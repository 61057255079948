import React from 'react';
import ColumnCenter from 'src/components/10-atoms/layout/ColumnCenter';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
const ThumbnailLayout = ({ style, ...colCenterProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(ColumnCenter, { style: [styles.container, style], ...colCenterProps });
};
const SIZE = 40;
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderRadius: theme.borderRadius.standard.md,
        borderWidth: 1,
        width: SIZE * 3, // aspect ratio
        height: SIZE * 3, // aspect ratio
        overflow: 'hidden',
    },
}));
export default withPress(ThumbnailLayout);
